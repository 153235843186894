body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
  position: relative;
}

body.modal-open {
  overflow: hidden;
}

#modal-root {
  position: relative;
  z-index: 500;
}

/* Prevent transitions on page load */
.block-transitions-on-page-load * {
  transition: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
}

a {
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: black;
}

a[role="button"] {
  cursor: pointer;
}

em,
cite {
  font-style: italic;
}

strong {
  font-weight: 700;
}

.j-link {
  color: #4391c9;
}

.j-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.j-field {
  height: 50px;
  padding: 0 12px;
  border: 1px solid #95989a;
  border-radius: 5px;
}

.j-textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #95989a;
  border-radius: 5px;
  resize: vertical;
}

label {
  display: block;
}

input,
select,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  white-space: normal;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  line-height: 24px;
  background: none;
  background-color: white;
}

::placeholder {
  font-family: 'Source Sans Pro', sans-serif;
}

/* Used by screen readers, but hidden visually */
.screen-reader {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
